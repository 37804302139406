@use '../../../styles/breakpoints.module';
@use '../../../styles/utils.module';

.input-select {
  display: flex;
  flex-direction: column;

  &__wrapper {
    @include utils.base-input;

    align-items: center;
    background: transparent;
    display: flex;
    outline: 0 !important;
    position: relative;

    &--error {
      border-bottom-color: var(--base-input-error-color);
    }

    &--disabled {
      background: var(--base-input-disabled-background);
      border-color: var(--base-input-disabled-color);
      color: var(--base-input-disabled-color);

      &:hover {
        border-bottom-color: var(--base-input-disabled-color);
      }
    }

    &--inverted {
      border-bottom-color: var(--color-neutrals-white);
      color: var(--base-input-label-color-inverted);
    }
  }

  &__label {
    font-size: var(--text-md);
    font-weight: 600;
    margin-bottom: var(--space-2);

    &--inverted {
      color: var(--color-neutrals-white);
    }
  }

  &__icon {
    font-size: var(--text-lg);
    padding: var(--space-2) var(--space-2) var(--space-2) 0;
  }

  &__container {
    position: revert-layer !important;
    width: 100%;
  }

  &__control {
    outline: revert-layer;
  }

  &__placeholder {
    color: var(--base-input-placeholder-color);

    &--inverted {
      color: var(--base-input-placeholder-color-inverted);
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    left: 0px;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 20;
  }

  &__menu-items {
    background-color: var(--base-input-options-background);
    border-radius: 4px;
    box-shadow: var(--component-selector-box-shadow);
    color: var(--base-input-options-color);

    &--inverted {
      background-color: var(--base-input-options-background);
      box-shadow: var(--component-selector-box-shadow);
      color: var(--base-input-options-color);
    }
  }

  &__option-container {
    align-items: center;
    display: flex;
  }

  &__option {
    padding: var(--space-2);
  }

  &__option-focused {
    background-color: var(--base-input-options-hover);
    padding: var(--space-2);

    &--inverted {
      background-color: var(--base-input-options-hover);
      color: var(--base-input-options-color-focus-inverted);
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  &__option-searchable {
    padding: var(--space-2);
  }

  &__option-focused-searchable {
    background-color: var(--base-input-options-hover);
    padding: var(--space-2);

    &--inverted {
      background-color: var(--base-input-options-hover);
      color: var(--base-input-options-color-focus-inverted);
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  &__option-image {
    height: 24px;
    margin-right: var(--space-2);
    width: 24px;

    > div {
      height: 100%;
    }

    img {
      object-fit: contain;
      object-position: center;
    }
  }

  &__indicator-separator {
    margin-left: var(--space-2);

    &--inverted {
      margin-left: var(--space-2);

      svg {
        fill: #fff;
      }
    }
  }

  &__error {
    color: var(--base-input-error-color);
    font-size: var(--base-input-error-font-size);
    margin-top: var(--space-2);
  }
}
