/* stylelint-disable no-descending-specificity */
@use '../../../styles/breakpoints.module';
@use '../../../styles/vars.module';
@use '../../../styles/utils.module';

.carousel-wrapper :global {
  position: relative;

  .swiper-pagination {
    display: none;
  }

  :local(.carousel.carousel--single) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  :local(.carousel).swiper-container {
    height: 100%;
    z-index: 0;

    .swiper-scrollbar {
      background: var(--color-neutrals-white);
      border-radius: calc(var(--space-1) / 2);
      bottom: 0;
      display: none;
      height: var(--space-1);
      left: var(--space-6);
      margin: 0 auto;
      max-width: calc(var(--util-max-screen-size) - 24px);
      overflow: hidden;
      right: var(--space-6);
      top: auto;
      width: 100%;

      .swiper-pagination-progressbar-fill {
        background: var(--color-neutrals-white);
        border-radius: calc(var(--space-1) / 2);
        height: var(--space-1);
      }
    }

    .swiper-scrollbar-drag {
      background: var(--color-neutrals-grey-4);
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: var(--component-carousel-old-controls-background-color);
      font-size: var(--text-3xl);
      height: 100%;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: var(--space-12);

      &::after {
        background-color: var(
          --component-carousel-old-controls-background-color-arrow
        );
        border: 1px solid;
        border-radius: 50%;
        color: var(--component-carousel-old-controls-color);
        content: '\e90f';
        font-family: var(--font-estore);
      }
    }

    .swiper-button-prev::after {
      content: '\e90e';
    }
  }

  :local(.carousel.carousel--buttons).swiper-container {
    padding: 0 var(--space-2);
    z-index: 0;

    @include breakpoints.for-medium-up {
      padding: var(--space-4) var(--space-2);
    }

    .swiper-button-next {
      opacity: 0;
    }

    .swiper-button-prev {
      left: 0;
      opacity: 0;
    }

    @include breakpoints.for-medium-up {
      &:hover .swiper-button-prev,
      &:hover .swiper-button-next {
        opacity: 1;
      }
    }

    .swiper-slide {
      display: flex;
      height: auto;
      justify-content: center;
    }

    .swiper-button-disabled {
      display: none;
    }
  }

  :local(.carousel.carousel--multi).swiper-container {
    padding: var(--space-4) var(--space-2);
    z-index: 0;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-disabled {
      display: none !important;
    }
  }

  :local(.carousel.carousel--multi-partners).swiper-container {
    padding: var(--space-4) 0 var(--space-4);
    z-index: 0;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-disabled {
      display: none !important;
    }

    @include breakpoints.for-small-up {
      height: auto;
      padding: var(--space-4) 0 var(--space-4);
    }

    .swiper-slide {
      height: auto;
    }
  }

  :local(.carousel.carousel--two-components).swiper-container {
    padding: var(--space-4) 0 0;
    z-index: 0;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-disabled {
      display: none !important;
    }

    @include breakpoints.for-small-up {
      height: auto;
      padding: var(--space-4) 0 var(--space-4);
    }

    .swiper-slide {
      height: auto;
    }
  }

  :local(.carousel.carousel--three-components).swiper-container {
    padding: var(--space-4) 0 0;
    z-index: 0;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-disabled {
      display: none !important;
    }

    @include breakpoints.for-small-up {
      height: auto;
      padding: var(--space-4) 0 var(--space-4);
    }

    .swiper-slide {
      height: auto;
    }
  }

  :local(.carousel.carousel--four-components).swiper-container {
    padding: var(--space-4) 0 0;
    z-index: 0;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-disabled {
      display: none !important;
    }

    @include breakpoints.for-small-up {
      height: auto;
      padding: var(--space-4) 0 var(--space-4);
    }

    .swiper-slide {
      height: auto;
    }
  }

  :local(.carousel.carousel--banners).swiper-container {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-disabled {
      display: none !important;
    }

    .swiper-wrapper {
      @include breakpoints.for-small-up {
        margin-bottom: var(--space-4);
        max-width: calc(vars.$container-size - var(--space-12));
        overflow: visible;
      }

      @include breakpoints.for-large-up {
        margin-bottom: var(--space-4);
      }
    }

    .swiper-slide {
      height: auto;
    }
  }
}

@keyframes animation-loading {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.carousel-controls {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto;
    margin: 0 var(--space-4);
    max-width: var(--util-carousel-container-max-width);

    @include breakpoints.for-small-up {
      grid-template-columns: 1fr 4fr 1fr;
      margin: 0 auto;
    }

    &--variant-banners {
      grid-template-columns: 1fr 2fr;
      grid-template-rows: auto auto;

      @include breakpoints.for-small-up {
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: auto;
      }
      @include breakpoints.for-xlarge-up {
        margin: 0 auto;
      }
    }
  }

  &__play-pause {
    display: flex;
    grid-row: 1;

    &--variant-banners {
      grid-column: 1 / 3;

      @include breakpoints.for-small-up {
        grid-row: 1;
      }
    }

    button {
      color: var(--component-carousel-controls-color);

      &:hover {
        color: var(--component-carousel-controls-color-hover);
      }

      &:active {
        color: var(--component-carousel-controls-color-active);
      }
    }
  }

  &__loading-bars {
    align-self: center;
    display: flex;
    grid-column: 2 / 3;
    grid-row: 1;
    justify-content: space-between;
    justify-self: center;
    margin-bottom: 0;
    width: 90%;

    @include breakpoints.for-small-up {
      max-width: 316px;
    }

    &--variant-banners {
      grid-column: 2 / 3;
      margin-bottom: var(--space-4);
      margin-top: var(--space-4);
      width: 90%;

      @include breakpoints.for-small-up {
        grid-column: 2 / 3;
        justify-self: center;
        margin: 0;
        width: 30%;
      }
    }

    &__bar {
      background-color: var(--component-carousel-loading-bar);
      flex-grow: 1;
      height: 3px;

      &--active {
        animation-duration: 6s;
        animation-name: animation-loading;
        animation-timing-function: linear;
        background-color: var(--component-carousel-loading-bar-active);
        height: 3px;
      }

      &--touched {
        background-color: var(--component-carousel-loading-bar-active);
        height: 3px;
      }
    }
  }
}
