@use '../../../styles/breakpoints.module';

$col6-tile-width: 150;
$col5-tile-width: 180;
$col3-tile-width: 300;

.partner-list {
  display: grid;
  grid-gap: var(--space-4);
  width: 100%;

  @include breakpoints.for-medium-up {
    grid-gap: var(--space-6);
  }

  &__link {
    background-color: var(--module-partner-tile-default-background);
    border: 1px solid var(--color-neutrals-grey-5);
    border-radius: var(--module-partner-tile-default-border-radius);
    box-sizing: border-box;
    color: unset;
    display: inline-block;
    text-decoration: none;
    transition: transform 0.2s;

    @include breakpoints.for-large-up {
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }

  &__link--hero {
    @include breakpoints.for-large-up {
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__hero-versatile-card {
    border: none;
    box-shadow: none;

    > div:nth-child(2) {
      padding: 0;

      > div > div {
        padding: var(--space-4);

        @include breakpoints.for-small-up {
          padding: var(--space-6);
        }
      }
    }
  }

  &__partner-tile {
    background-color: var(--module-partner-tile-default-background);
    border-radius: var(--module-partner-tile-default-border-radius);
    box-shadow: none;
    padding: var(--module-partner-tile-default-vertical-padding)
      var(--module-partner-tile-default-horizontal-padding);
  }

  &--col6 {
    grid-template-columns: 1fr;

    @media (min-width: breakpoints.get-breakpoint-grid-col($col6-tile-width, 3)) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: breakpoints.get-breakpoint-grid-col($col6-tile-width, 4)) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: breakpoints.get-breakpoint-grid-col($col6-tile-width, 5)) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include breakpoints.for-xlarge-up {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &--col5 {
    grid-template-columns: 1fr;

    @media (min-width: breakpoints.get-breakpoint-grid-col($col5-tile-width, 3)) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: breakpoints.get-breakpoint-grid-col($col5-tile-width, 4)) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include breakpoints.for-xlarge-up {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &--col4 {
    grid-template-columns: 1fr;

    @media (min-width: breakpoints.get-breakpoint-grid-col($col3-tile-width, 2)) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoints.for-large-up {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--col3 {
    grid-template-columns: 1fr;

    @media (min-width: breakpoints.get-breakpoint-grid-col($col3-tile-width, 2)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: breakpoints.get-breakpoint-grid-col($col3-tile-width, 3)) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--col2 {
    grid-template-columns: 1fr;

    @include breakpoints.for-medium-up {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--col2-strict {
    grid-template-columns: 1fr 1fr;
  }
}

.partner-list-carousel {
  &__item {
    cursor: pointer;
    @include breakpoints.for-large-up {
      &:hover {
        transform: scale(1.05);
        transition: transform 0.2s;
      }
    }
  }

  &__link {
    text-decoration: none;
  }
}
