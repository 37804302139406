.load-more {
  margin: auto;
  max-width: 280px;
  text-align: center;

  &__button {
    margin-bottom: 12px;
  }

  &__bar {
    background-color: var(--module-load-more-bar-background-color);
    border-radius: 2px;
    height: 5px;
    margin-bottom: 10px;
    width: 100%;
  }

  &__bar-fill {
    background-color: var(--module-load-more-bar-fill-background-color);
    border-radius: 2px;
    height: 100%;
    width: 100%;
  }

  &__viewed {
    color: var(--module-load-more-viewed-color);
    font-size: 14px;
  }
}
