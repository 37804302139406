@use '../../../styles/breakpoints.module';

$label-width: 85px;

.partners-section {
  background: var(--module-cop-background);
  flex: 1 0 auto;
  overflow: hidden;
  padding-bottom: var(--space-10);

  &__loader {
    display: flex;
    height: 2em;
  }

  &__partner-list {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    padding: var(--space-6) var(--space-4);
  }

  &__partner-list-container {
    margin-top: var(--space-6);
  }

  &__filters {
    background-color: var(--module-cop-filters-background-color);
    box-shadow: var(--module-cop-filters-box-shadow);
    display: flex;
    flex-direction: column;
  }

  &__filters-container {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: var(--util-max-screen-size);
    padding: var(--space-6) var(--space-4);
  }

  &__filters-categories {
    align-items: center;
    border-bottom: solid 1px var(--module-cop-filters-border-bottom-color);
    display: flex;
    padding-bottom: var(--space-2);
    padding-top: var(--space-4);
  }

  &__sort-wrapper {
    align-items: flex-end;
    display: flex;
    padding-bottom: var(--space-2);
    padding-top: var(--space-4);
  }

  &__sort-list {
    min-width: 220px;
  }
}
