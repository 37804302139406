@use './breakpoints.module';

@mixin select-element($arg) {
  user-select: $arg;
}

@mixin button-reset {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  padding: 0;
}

@mixin base-input {
  $block: &;

  background: var(--base-input-background);
  border: var(--base-input-border);
  border-radius: var(--base-input-border-radius);
  box-sizing: border-box;
  color: var(--base-input-color);
  font-size: var(--base-input-font-size);
  height: var(--space-12);
  padding: var(--space-2) var(--space-4);

  &:disabled {
    background: var(--base-input-disabled-background);
    border-color: var(--base-input-disabled-border-color);
    color: var(--base-input-disabled-color);
  }

  &:focus-visible,
  &:has(input:focus-visible) {
    border: var(--base-input-border-focus);
  }

  &--error {
    border-color: var(--base-input-error-color);

    &:hover {
      border-color: var(--base-input-error-color);
    }

    &:focus-visible {
      border-color: var(--base-input-error-color);
    }
  }

  #{$block}[read-only] {
    border-radius: 0px;
    outline: none;
  }

  &::placeholder {
    color: var(--base-input-placeholder-color);
  }
}

// Full screen overlay
%overlay {
  background-color: rgba(0, 0, 0, 50%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  @include breakpoints.for-medium-up {
    align-items: center;
  }
}

/**
 * Remove overflow when Modal is open.
 */

.hideOverflow {
  overflow: hidden;
}
