.filter-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  &__filter-item {
    background-color: var(--component-filter-list-background-color);
    border-radius: 4px;
    color: var(--component-filter-list-color);
    line-height: 150%;
    margin: 0 var(--space-2) var(--space-2) 0;

    &:hover {
      background-color: var(--component-filter-list-hover-background-color);
      color: var(--component-filter-list-hover-color);
    }

    &--selected {
      background: var(--component-filter-list-selected-background-color);
      color: var(--component-filter-list-selected-color);

      &:hover {
        background-color: var(
          --component-filter-list-selected-hover-background-color
        );
        color: var(--component-filter-list-selected-hover-color);
      }
    }
  }

  &__reset {
    margin-left: auto;
  }
}
